import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-license-pro'
import './scss/style.scss'

LicenseInfo.setLicenseKey('YOUR_LICENSE_KEY')
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const navigate = useNavigate()
  const yourtoken = 'gJRm6z52MxfxpvucVdaYqayyYdj2S80ESZ0G7AZkC7BJOjC7L6IkbqwyUdmH55Ug'
  useEffect(() => {
    // Проверяем наличие токена в локальном хранилище
    const token = localStorage.getItem('token')

    // Если токен отсутствует, перенаправляем на страницу логина
    if (!token || token !== yourtoken) {
      navigate('/login')
    }
  }, [navigate])

  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route exact path="/register" name="Register Page" element={<Register />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  )
}

export default App
